import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMeAsync, mergeUserNodeData } from "shared/redux/user.slice";
import AuthPortalBusiness from "./AuthPortalBusiness";
import { setSharedNodeData } from "shared/redux/shared.slice";

export default ({ children }) => {
  const { loading, hasCheckedAuthentification } = useSelector(
    (state) => state.user?.me
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getMeAsync({
        payload: {
          query: {
            variant: "business",
          },
        },
        options: {
          onError: () => {},
          finally: () =>
            dispatch(
              mergeUserNodeData({
                node: "me",
                data: {
                  hasCheckedAuthentification: true,
                },
              })
            ),
        },
      })
    );
  }, []);

  if (loading) {
    return <></>;
  }

  if (!hasCheckedAuthentification) {
    return <></>;
  }

  return <>{children}</>;
};
