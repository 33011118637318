import classnames from "classnames";
import { tabsMapBusiness } from "business/constants/tabsBusiness.constant";
import useTabsBusiness from "business/hooks/useTabsBusiness";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default () => {
  const tabs = useTabsBusiness("sidebar");

  const [currentTab, setCurrentTab] = useState();

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    setCurrentTab(_.chain(location.pathname).split("/").nth(1).value());
  }, [location.pathname]);

  const onTabClick = (tab) => {
    navigate(tab?.route);
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-start border-r-[1px] border-custom-border-main _sidebarShadowAnimation">
      <div className="w-full h-[70px] flex flex-col items-center justify-center text-2xl font-light _fontTangerine tracking-widest border-b-[1px] border-custom-border-main mb-2">
        <span className=" w-[40px] h-[40px] flex flex-col items-center justify-center text-black bg-secondary-main rounded-full">
          S
        </span>
      </div>

      {_.map(tabs, (tab, index) => (
        <SidebarTabItem
          tab={tab}
          key={index}
          onClick={onTabClick}
          selected={currentTab === tab.value}
        />
      ))}

      <div className="grow w-full flex flex-col items-center justify-end">
        <SidebarTabItem
          tab={tabsMapBusiness.me}
          className="border-t-[1px] border-custom-border-main justify-self-end"
          onClick={onTabClick}
          selected={currentTab === tabsMapBusiness.me.value}
        />
      </div>
    </div>
  );
};

const SidebarTabItem = ({ tab, selected, className, onClick }) => {
  return (
    <div
      className={classnames(
        "w-full h-[60px] flex flex-col justify-center items-center",
        className
      )}
      onClick={() => onClick(tab)}
    >
      <div
        className={classnames(
          "w-full h-full flex flex-col items-center justify-center -tooltip -tooltip-right -tooltip-white -tooltip-primary z-20 cursor-pointer"
        )}
        data-tip={tab?.label}
      >
        <div
          className={classnames(
            "w-[50px] h-[50px] flex flex-col items-center justify-center",
            {
              "bg-primary-main rounded-full": selected,
              "hover:bg-background-light-0 rounded-full": !selected,
            }
          )}
        >
          {selected ? tab.selectedIcon : tab?.defaultIcon}
        </div>
      </div>
    </div>
  );
};
