import { Icon } from "@iconify/react";
import classnames from "classnames";
import { tailwindTheme } from "shared/constants/theme.constant";

export default ({ data, onClick, className }) => {
  return (
    <div
      className={classnames(
        "w-full h-[50px] flex flex-row items-center justify-start border-b-[1px] border-custom-border-main cursor-pointer",
        className
      )}
      onClick={() => onClick?.(data)}
    >
      {data?.icon && (
        <Icon
          icon={data?.icon}
          fontSize={22}
          color={tailwindTheme.colors.custom.icon.main}
        />
      )}
      <div className="text-lg ml-4">{data.label}</div>
    </div>
  );
};
