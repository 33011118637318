import { TypeAnimation } from "react-type-animation";

export default ({ children }) => {
  return (
    <div className="w-screen h-screen flex flex-row items-start justify-between overflow-hidden">
      <div className="text-2xl font-normal absolute top-2 left-4">Swiftr</div>
      <div className="w-1/2 h-screen relative overflow-hidden _authShadowAnimation">
        <div className="w-full h-full relative flex flex-col items-center justify-center">
          <TypeAnimation
            sequence={[
              `" Gérez votre business en toute simplicité "`,
              3000,
              // `" Améliorez l'expérience utilisateur de vos clients "`,
              // 3000,
              `" Augmentez votre productivité "`,
              3000,
              `" Calculez vos revenus en temps réel "`,
              3000,
            ]}
            wrapper="span"
            speed={80}
            repeat={Infinity}
            omitDeletionAnimation
            cursor={false}
            className="text-3xl min-h-[100px] text-center px-[50px]"
          />
          <div className="w-full text-xl text-center font-normal absolute bottom-[10px] animate-pulse ">
            Le marketplace est accessible depuis votre smartphone
          </div>
        </div>
      </div>

      <div className="w-1/2 h-full flex flex-row items-center justify-center">
        <div className="w-full max-w-[400px] h-full">{children}</div>
      </div>
    </div>
  );
};
