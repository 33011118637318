import _ from "lodash";
import { lazy, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routesBusinessConstant from "business/constants/routesBusiness.constant";
import { ApplicationContext, AuthModalContext } from "context";
import RootRouterBusiness from "RootRouterBusiness";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <RootRouterBusiness />,
      children: routesBusinessConstant,
    },
  ],
  {
    basename: "/",
  }
);

export default () => {
  const [variantContext, setApplicationContext] = useState("business");
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  return (
    <ApplicationContext.Provider value={variantContext}>
      <AuthModalContext.Provider value={[isAuthModalOpen, setIsAuthModalOpen]}>
        <RouterProvider router={router} />
      </AuthModalContext.Provider>
    </ApplicationContext.Provider>
  );
};
