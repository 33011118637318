import BusinessSettingsBusiness from "./shared/BusinessSettingsBusiness";
import useBusiness from "business/hooks/useBusiness";

export default () => {
  const business = useBusiness();

  return (
    <div className="w-full px-4 lg:px-0">
      <BusinessSettingsBusiness />
    </div>
  );
};
