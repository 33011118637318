import useBaseSelector from "shared/hooks/useBaseSelector";
import BaseDrawer from "../base/BaseDrawer";
import { useDispatch } from "react-redux";
import { setSharedNodeData } from "shared/redux/shared.slice";
import BaseList from "../base/BaseList";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "context";
import _ from "lodash";

export default () => {
  const variant = useContext(ApplicationContext);

  const { isOpen } = useBaseSelector("shared.drawer");

  const [drawerItems, setDrawerItems] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setDrawerItems(
      _.chain(drawerItemsConstant)
        .pick(drawerItemsMap[variant])
        .values()
        .value()
    );
  }, []);

  const onClose = () => {
    dispatch(
      setSharedNodeData({
        node: "drawer",
        data: {
          isOpen: false,
        },
      })
    );
  };

  const onItemClick = (data) => {
    onClose();
    navigate(data?.route);
  };

  return (
    <BaseDrawer isOpen={isOpen} onClose={onClose}>
      <div className="w-full h-full bg-background-main pt-[50px]">
        <BaseList
          data={drawerItems}
          onItemClick={onItemClick}
          itemClassName="pl-8"
        />
      </div>
    </BaseDrawer>
  );
};

const drawerItemsConstant = {
  about: {
    icon: "hugeicons:information-circle",
    label: "A propos",
    route: "/about",
  },
  downloadBusiness: {
    icon: "hugeicons:circle-arrow-down-03",
    label: "Télecharger l'appli Business",
    route: "/public/downloads/swiftr.business.apk",
  },
};

const drawerItemsMap = {
  public: ["about", "downloadBusiness"],
  business: ["about"],
};

const list = [
  {
    icon: "hugeicons:information-circle",
    label: "A propos",
    route: "/about",
  },
];
