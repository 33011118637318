import _ from "lodash";
import BaseListItem from "./BaseListItem";
import classnames from "classnames";

export default ({ data, onItemClick, className, itemClassName }) => {
  return (
    <div className={classnames("w-full h-auto pb-4", className)}>
      {_.map(data, (item, index) => (
        <BaseListItem
          data={item}
          key={index}
          onClick={onItemClick}
          className={classnames(itemClassName, {
            'border-t-[1px]': index === 0
          })}
        />
      ))}
    </div>
  );
};
