import { useState, useEffect } from "react";
import Login from "shared/components/user/Login";
import Register from "shared/components/user/Register";
import classnames from "classnames";
import GoogleLogin from "../shared/core/GoogleLogin";
import { TypeAnimation } from "react-type-animation";

export const Auth = ({ className, ...props }) => {
  const [view, setView] = useState("register");

  return (
    <div
      className={classnames(
        "w-full h-full flex flex-col items-center justify-end",
        className
      )}
    >
      <GoogleLogin />
    </div>
  );
};

export const ManualAuth = ({ className, ...props }) => {
  const [view, setView] = useState("register");

  return (
    <div
      className={classnames(
        "w-full h-full flex flex-col items-center justify-end",
        className
      )}
    >
      {view === "login" && (
        <Login onChangeToRegisterClick={() => setView("register")} {...props} />
      )}
      {view === "register" && (
        <Register onChangeToLoginClick={() => setView("login")} {...props} />
      )}

      <span className="w-full">
        <div className="w-full h-[1px] bg-custom-border-main mb-4"></div>
        <GoogleLogin />
      </span>
    </div>
  );
};

export const MobileAuth = (props) => (
  <div className="w-full h-screen flex flex-col items-center justify-center px-4">
    <div className="text-2xl font-normal absolute top-2 left-4">Swiftr</div>
    <TypeAnimation
      sequence={[
        `" Gérez votre business en toute simplicité "`,
        3000,
        // `" Améliorez l'expérience utilisateur de vos clients "`,
        // 3000,
        `" Augmentez votre productivité "`,
        3000,
        `" Calculez vos revenus en temps réel "`,
        3000,
      ]}
      wrapper="span"
      speed={80}
      repeat={Infinity}
      omitDeletionAnimation
      cursor={false}
      className="text-2xl h-auto text-center"
    />
    <div className="w-full absolute bottom-4 px-4">
      <GoogleLogin />
    </div>
  </div>
);

export const MobileManualAuth = (props) => (
  <div className="w-full h-screen flex flex-col items-center justify-start px-4 py-[200px]">
    <ManualAuth {...props} />
  </div>
);
