import useSocketEventsBusiness from "business/hooks/useSocketEventsBusiness";
import useBaseSelector from "shared/hooks/useBaseSelector";
import BusinessPortal from "../business/BusinessPortal";
import RootBusinessPortalBusiness from "../business/RootBusinessPortalBusiness";

export default ({ children }) => {
  const { data: user } = useBaseSelector("user.me");

  useSocketEventsBusiness();

  if (user && !user?.relatedBusiness) {
    return <BusinessPortal />;
  }

  return <>{children}</>;
};
