import classnames from "classnames";
import useScrollWatch from "shared/hooks/useScrollWatch";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { ExploreSearch } from "./Input";
import classNames from "classnames";
import useBaseSelector from "shared/hooks/useBaseSelector";
import { ApplicationPeriodContext, AuthModalContext } from "context";

export default ({ tabs, watchRouteDirectoryIndex, badgeNumberMap }) => {
  const [isAuthSheetOpen, setIsAuthSheetOpen] = useContext(
    AuthModalContext
  );
  const applicationPeriod = useContext(ApplicationPeriodContext);

  const showSearch = useBaseSelector("shared.tabs.showSearch");

  const [currentTab, setCurrentTab] = useState();
  const [isHidden, setIsHidden] = useState(false);
  const [scrollDownHide, setScrollDownHide] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const { scrollDirection } = useScrollWatch();

  useEffect(() => {
    const value = _.chain(location.pathname)
      .split("/")
      .nth(watchRouteDirectoryIndex)
      .value();

    const isTabsHidden = _.some(hideTabsPathnamePattern, (el) =>
      _.includes(location.pathname, el)
    );

    setCurrentTab(value);
    setIsHidden(isTabsHidden);
  }, [location.pathname]);

  useEffect(() => {
    if (scrollDirection === "down" && !scrollDownHide) {
      setScrollDownHide(true);
    } else if (scrollDirection === "up" && scrollDownHide) {
      setScrollDownHide(false);
    }
  }, [scrollDirection]);

  return (
    <div
      className={classnames(
        "flex flex-row items-center justify-center h-auto w-full fixed bottom-2 px-2 z-40"
      )}
    >
      <div
        className={classNames(
          "w-full h-auto  max-w-md flex flex-col items-start justify-start rounded-xl _glass",
          {
            hidden: isHidden,
            "translate-y-[100px] duration-300":
              scrollDownHide && currentTab !== "explore",
            "translate-y-[180px] duration-300":
              scrollDownHide && currentTab === "explore",
            "duration-300": !scrollDownHide,

            _bottomTabsShadowAnimation:
              applicationPeriod === "evening" || applicationPeriod === "night",
          }
        )}
      >
        <ExploreSearch
          className={classNames({
            hidden: !showSearch,
          })}
          placeholder="Rechercher restaurants,cafés,bars"
        />
        <div
          className={classnames(
            "w-full h-[70px] flex flex-row items-center justify-between px-1",
            {}
          )}
        >
          {_.map(tabs, (tab, index) => (
            <TabButton
              data={tab}
              key={index}
              onClick={() => navigate(tab?.route)}
              selected={
                currentTab === tab.value ||
                tab?.isSelected?.(location?.pathname)
              }
              badgeNumber={badgeNumberMap?.[tab.value]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const TabButton = ({
  data,
  containerClassName,
  onClick,
  badgeNumber,
  selected = false,
}) => {
  if (selected) {
    return (
      <div
        onClick={() => onClick(data)}
        className={classnames(
          containerClassName,
          "w-full h-auto place-items-center pointer"
        )}
      >
        <div className="flex flex-row items-center justify-center bg-primary-main rounded-full w-[100px] h-[40px]">
          <div className="mr-1">
            {selected ? data?.selectedIcon : data?.defaultIcon}
          </div>
          <div
            className={classnames("text-sm", {
              "text-gray-400": !selected,
              "text-white": selected,
            })}
          >
            {data?.label}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      onClick={() => onClick(data)}
      className={classnames(
        containerClassName,
        "w-full h-auto flex flex-col justify-center items-center pointer relative"
      )}
    >
      <div className="mb-1">
        {selected ? data?.selectedIcon : data?.defaultIcon}
      </div>
      {badgeNumber > 0 && (
        <div className="w-[25px] h-[25px] rounded-full flex flex-col items-center justify-center absolute -top-3 -left-1 bg-primary-main">
          <div className="text-white text-sm font-semibold">{badgeNumber}</div>
        </div>
      )}
    </div>
  );
};

const hideTabsPathnamePattern = [
  "notification",
  "business/",
  "order/",
  "verify-email",
];
