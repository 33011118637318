import BaseButton from "shared/components/shared/base/BaseButton";
import BaseInput from "shared/components/shared/base/BaseInput";
import { createBusinessAsync } from "shared/redux/business.slice";
import { getMeAsync } from "shared/redux/user.slice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import _ from "lodash";
import BaseSelect from "shared/components/shared/base/BaseSelect";
import { businessTypesOptions } from "shared/constants/business.constant";

export default ({ onBackClick }) => {
  const { loading } = useSelector((state) => state.business.form);

  const [form, setForm] = useState({
    name: "",
    type: "",
  });

  const dispatch = useDispatch();

  const canSubmit = !_.isEmpty(form.name) && !_.isEmpty(form.type);

  const onChange = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    dispatch(
      createBusinessAsync({
        payload: form,
        options: {
          onSuccess: () => {
            dispatch(
              getMeAsync({
                query: {
                  variant: "business",
                },
              })
            );
          },
        },
      })
    );
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-between px-4 py-[50px] ">
      <Icon
        icon="hugeicons:link-backward"
        color="white"
        fontSize={28}
        onClick={onBackClick}
        className="cursor-pointer absolute top-4 left-4 lg:left-[calc(50%+15px)]"
      />

      <div className="w-full h-auto text-2xl lg:text-2xl text-center">
        Créer Votre Business
      </div>
      <div className="w-full h-auto">
        <BaseInput
          placeholder="Nom Business"
          className="mb-8"
          value={form.name}
          onChange={(e) => onChange("name", e.target.value)}
        />

        <BaseSelect
          list={businessTypesOptions}
          onChange={(item) => onChange("type", item.value)}
          placeholder="Sélectionner votre type de business"
          className="mb-8"
          bottomSheetProps={{
            containerClassName: "lg:ml-0 lg:ml-[calc(50%+40px)]",
          }}
        />

        <BaseButton.Contained primary
          className="w-full rounded-full"
          onClick={onSubmit}
          loading={loading}
          disabled={!canSubmit}
        >
          Commencer
        </BaseButton.Contained>
      </div>
    </div>
  );
};
