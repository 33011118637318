import WithResponsive from "shared/components/shared/utils/WithResponsive";
import { RightContentTabletContainer } from "../shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import {
  LayoutContentContainer,
  TitledContent,
} from "shared/components/shared/core/Container";
import { appIcons } from "shared/constants/icons.constant";
import _ from "lodash";
import { OverviewCardV2 } from "../shared/OverviewCard";
import { tailwindTheme } from "shared/constants/theme.constant";
import colors from "tailwindcss/colors";
import { useEffect, useState } from "react";
import { orderStatusValueMap } from "shared/constants/order.constant";
import { getBusinessOverviewService } from "shared/services/analytics.service";

const BusinessDashboardTab = () => {
  const [cards, setCards] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      setLoading(true);
      const { data: overview } = await getBusinessOverviewService();
      setCards(buildOverviewCards(overview));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <LayoutContentContainer>
      <TitledContent title="Aujourd'hui">
        <div className="w-full h-auto grid grid-cols-2 lg:grid-cols-4 gap-x-2 gap-y-2 lg:gap-x-4 lg:gap-y-2">
          {_.map(cards, (card, index) => (
            <OverviewCardV2 data={card} key={index} />
          ))}
        </div>
      </TitledContent>
    </LayoutContentContainer>
  );
};

const BusinessDashboardTabTablet = () => {
  return <BusinessDashboardTab />;
};

export default WithResponsive(
  () => <BusinessDashboardTab />,
  () => (
    <RightContentTabletContainer
      breadCrumbItems={[tabsBreadcrumbs.dashboard]}
      firstContentMaxWidth={false}
    >
      <BusinessDashboardTabTablet />
    </RightContentTabletContainer>
  )
);

const cards = [
  {
    title: "Tables utilisé",
    value: "20",
    icon: appIcons.tables.default,
    iconBgColor: tailwindTheme.colors.primary.main,
  },
  {
    title: "En attente",
    value: "5",
    icon: appIcons.order.default,
    iconBgColor: tailwindTheme.colors.primary.light?.[0],
  },
  {
    title: "En cours",
    value: "12",
    icon: appIcons.order.default,
    iconBgColor: colors.blue[500],
  },
  {
    title: "Términé",
    value: "12",
    icon: appIcons.order.default,
    iconBgColor: colors.green[700],
  },
];

const buildOverviewCards = (overview) => {
  return [
    {
      title: "Tables utilisé",
      value: overview?.tablesRatio,
      icon: appIcons.tables.default,
      iconBgColor: tailwindTheme.colors.primary.main,
    },
    {
      title: "En attente",
      value: overview?.[orderStatusValueMap.waitingAcceptance],
      icon: appIcons.order.default,
      iconBgColor: tailwindTheme.colors.primary.light?.[0],
    },
    {
      title: "En cours",
      value: overview?.[orderStatusValueMap.inProgress],
      icon: appIcons.order.default,
      iconBgColor: colors.blue[500],
    },
    {
      title: "Prét",
      value: overview?.[orderStatusValueMap.ready],
      icon: appIcons.order.default,
      iconBgColor: colors.green[700],
    },
    {
      title: "Términé",
      value: overview?.[orderStatusValueMap.done],
      icon: appIcons.order.default,
      iconBgColor: colors.green[700],
    },
  ];
};
