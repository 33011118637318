import WithResponsive from "shared/components/shared/utils/WithResponsive";
import AuthContainerBusiness from "./AuthContainerBusiness";
import { Auth, ManualAuth, MobileAuth } from "../../../shared/components/user/Auth";

const DesktopAuth = () => {
  return (
    <AuthContainerBusiness>
      <div className="w-full h-full flex flex-col items-center justify-start py-[50px]">
        <ManualAuth variant="business" />
      </div>
    </AuthContainerBusiness>
  );
};

const MobileAuthBusiness = () => <MobileAuth variant="business" />;

export default WithResponsive(MobileAuthBusiness, DesktopAuth);
