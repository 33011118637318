import Drawer from "react-modern-drawer";

export default ({ children, isOpen, onClose, className }) => {
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction="left"
      className={className}
      duration={200}
      size="90vw"
    >
      {children}
    </Drawer>
  );
};
