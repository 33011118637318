import _ from "lodash";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import { tailwindTheme } from "shared/constants/theme.constant";
import classNames from "classnames";

export const RightContentTabletContainer = ({
  children,
  useBreadCrumbs = true,
  breadCrumbItems = [],
  firstContentMaxWidth = false,
  firstContentClassName,
  contentClassName,
}) => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full">
      {useBreadCrumbs && (
        <div className="-breadcrumbs mb-4">
          <ul className="w-auto h-auto">
            <li className="cursor-pointer">
              <Icon
                icon="hugeicons:home-01"
                fontSize={22}
                color={tailwindTheme.colors.custom.tab.selected}
                onClick={() => navigate("/")}
              />
            </li>
            {_.map(breadCrumbItems, (item, index) => (
              <BreadcrumbItem
                key={index}
                data={item}
                contained={index === breadCrumbItems?.length - 1}
              />
            ))}
          </ul>
        </div>
      )}
      <div
        className={classNames(
          "w-full h-full flex flex-row items-start justify-start",
          contentClassName
        )}
      >
        <div
          className={classNames(
            "grow h-full overflow-y-scroll relative pb-[80px]",
            {
              "max-w-[450px]": firstContentMaxWidth,
              "pr-4": children?.[1],
            },
            firstContentClassName
          )}
        >
          {children?.[0] ?? children ?? <></>}
        </div>

        {children?.[1] && (
          <div className="xl:min-w-[350px] xl:max-w-[350px] md:min-w-[300px] md:max-w-[300px] h-full overflow-y-scroll relative pb-4">
            {children?.[1] ?? <></>}
          </div>
        )}
      </div>
    </div>
  );
};

const BreadcrumbItem = ({ data, contained }) => {
  const navigate = useNavigate();

  const params = useParams();

  const item = _.isFunction(data) ? data(params) : data;

  return (
    <li>
      <div
        className={classNames(
          "flex flew-row items-center justify-start cursor-pointer",
          {
            "bg-secondary-main px-8 py-1 rounded-xl": contained,
          }
        )}
        onClick={() => item?.link && navigate(item?.link)}
      >
        {item?.icon && (
          <Icon
            icon={item.icon}
            fontSize={22}
            color={
              contained ? "black" : tailwindTheme.colors.custom.tab.selected
            }
          />
        )}
        <div
          className={classNames("text-lg", {
            "ml-2": Boolean(item?.icon),
            "text-black": contained,
          })}
        >
          {item.title}
        </div>
      </div>
    </li>
  );
};

export const WithResponsiveRightContentContainer = (
  Component,
  containerProps
) =>
  WithResponsive(Component, (props) => (
    <RightContentTabletContainer {...containerProps}>
      <Component {...props} />
    </RightContentTabletContainer>
  ));
