import BaseCard from "shared/components/shared/base/BaseCard";
import { tailwindTheme } from "shared/constants/theme.constant";
import BottomSheetListSelection from "../core/BottomSheetListSelection";
import { useState } from "react";
import { Icon } from "@iconify/react";
import classNames from "classnames";

export default ({
  list,
  placeholder,
  onChange,
  rightIcon,
  className,
  bottomSheetProps,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItem] = useState();

  const onSelect = (value) => {
    setItem(value);
    onChange?.(value);
  };

  return (
    <>
      <div
        className={classNames(
          "w-full h-auto flex flex-row items-center justify-between rounded-full _glassResponsivePerf _shadowInput cursor-pointer px-4 py-4",
          className
        )}
        onClick={() => setIsOpen(true)}
      >
        {item ? (
          <div className="text-lg">{item?.label}</div>
        ) : (
          <div className="text-lg text-custom-text-sub">{placeholder}</div>
        )}
        <Icon
          fontSize={24}
          icon={rightIcon}
          color={tailwindTheme.colors.custom.icon.main}
        />
      </div>
      <BottomSheetListSelection
        list={list}
        onItemClick={onSelect}
        bottomSheetProps={{
          isOpen,
          onClose: () => setIsOpen(false),
          ...bottomSheetProps,
        }}
      />
    </>
  );
};
