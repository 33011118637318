import { tailwindTheme } from "shared/constants/theme.constant";
import { IoSearch } from "react-icons/io5";
import classnames from "classnames";
import { useContext } from "react";
import { SearchContext } from "context";
import classNames from "classnames";

export const SearchBar = ({ className, ...props }) => {
  return (
    <div
      className={classnames(
        "w-full h-auto flex flex-row items-center justify-start rounded-lg px-4 py-2 bg-background-light-1 mb-4",
        className
      )}
    >
      <IoSearch
        color={tailwindTheme.colors?.custom.icon.sub}
        size={24}
        className="mr-2"
      />
      <input
        type="text"
        className="w-full border-none bg-transparent outline-none"
        {...props}
      />
    </div>
  );
};

export const ExploreSearch = ({ className, ...props }) => {
  const [search, setSearch] = useContext(SearchContext);

  return (
    <div className={classNames("w-full h-auto", className)}>
      <div className="w-full h-auto flex flex-row items-center justify-start px-4 py-6">
        <IoSearch
          color={tailwindTheme.colors?.palette?.searchIcon}
          size={24}
          className="mr-2"
        />
        <input
          type="text"
          className="w-full text-lg border-none bg-transparent outline-none"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          {...props}
        />
      </div>
    </div>
  );
};
