import BaseButton from "shared/components/shared/base/BaseButton";
import BaseDivider from "shared/components/shared/base/BaseDivider";
import { useState } from "react";
import BusinessCreate from "./BusinessCreate";
import { useDispatch } from "react-redux";
import { logoutUserAsync } from "shared/redux/user.slice";
import QRCode from "react-qr-code";
import useMe from "shared/hooks/user/useMe";
import _ from "lodash";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import AuthContainerBusiness from "../user/AuthContainerBusiness";
import { tailwindTheme } from "shared/constants/theme.constant";
import { Icon } from "@iconify/react";

const BusinessPortalMobile = () => {
  const user = useMe();

  const [view, setView] = useState("wait");

  const dispatch = useDispatch();

  const onDisconnectClick = () => {
    dispatch(logoutUserAsync());
  };

  if (view === "wait") {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center px-6">
        <div className="w-full h-auto flex flex-col items-center justify-between">
          <div className="w-full flex flex-col items-center justify-center">
            <div className="w-full text-lg lg:text-2xl text-center mb-4">
              Se connecter a un restautant/café existant avec un QR code
            </div>
            <div className="w-[200px] h-[200px] bg-white p-2">
              <QRCode
                value={JSON.stringify(_.pick(user, ["id", "fullName"]))}
                className="w-full h-full"
              />
            </div>
          </div>
          <BaseDivider className="mt-8 mb-8" />
          <div className="w-full h-auto flex flex-col items-center justify-center">
            <BaseButton.Contained primary
              className="w-full flex flex-row items-center justify-center rounded-full mb-4"
              onClick={() => setView("create")}
            >
              Créer votre business
            </BaseButton.Contained>
            <div
              className="w-auto flex flex-row items-center justify-start absolute top-4 left-4 lg:left-[calc(50%+15px)] cursor-pointer"
              onClick={onDisconnectClick}
            >
              <Icon
                fontSize={24}
                color={tailwindTheme.colors.custom.icon.main}
                icon="hugeicons:logout-05"
                className="mr-1"
              />
              <div className="lg:text-lg">Déconnecter</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (view === "create") {
    return <BusinessCreate onBackClick={() => setView("wait")} />;
  }

  return <></>;
};

const BusinessPortalTablet = () => (
  <AuthContainerBusiness>
    <BusinessPortalMobile />
  </AuthContainerBusiness>
);

export default WithResponsive(BusinessPortalMobile, BusinessPortalTablet);
