import { useEffect } from "react";
import {
  notificationSoundURL,
  socketURL,
} from "shared/constants/shared.constant";
import useAudio from "shared/hooks/useAudio";
import useBaseSelector from "shared/hooks/useBaseSelector";
import { io } from "socket.io-client";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { dispatchPushNewNotification } from "shared/dispatch/notification.dispatch";
import { toast } from "react-toastify";
import Toast from "shared/components/shared/core/Toast";
import {
  setBusinessUserDispatch,
  setBusinessUserNoneDispatch,
  setBusinessUserRoleUpdateDispatch,
} from "business/utils/socketHandlers";

let socket;

export default () => {
  const { data: user } = useBaseSelector("user.me");

  const [__, toggle] = useAudio(notificationSoundURL);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const data = user;

    if (socket) {
      console.log("disconnecting...");
      socket.disconnect();
    }

    socket = io(socketURL);

    socket.on("disconnect", () => {
      console.log("disconnected from server");
    });

    socket.on("connect", () => {
      console.log("connecting...");
      socket?.emit("user.connect", data, (data) => {
        _.chain(notificationHandlerMap)
          .keys()
          .forEach((event) => {
            socket.once(event, (data) => {
              dispatchPushNewNotification(dispatch, data);
              toast(
                <Toast
                  type={notificationHandlerMap[event]}
                  title={data?.title}
                  message={data?.message}
                />
              );
              toggle();
            });
          })
          .value();

        _.chain(changeHandlerMap)
          .keys()
          .forEach((event) => {
            socket.once(event, (data) => {
              const handler = changeHandlerMap?.[event];
              handler?.(dispatch, data);
            });
          })
          .value();
      });
    });
  }, [user]);
};

const notificationHandlerMap = {
  "notification.order.created": "success",
  "notification.order.delivered": "success",
  "notification.order.modified": "warning",
  "notification.order.deleted": "warning",

  "notification.business.user.role.update": "warning",
  "notification.business.user.delete": "warning",
  "notification.business.user.add": "success",
};

const changeHandlerMap = {
  "order.created": _.noop,
  "order.delivered": _.noop,
  "order.modified": _.noop,
  "order.deleted": _.noop,
  "business.user.role.update": setBusinessUserRoleUpdateDispatch,
  "business.user.delete": setBusinessUserNoneDispatch,
  "business.user.add": setBusinessUserDispatch,
};
