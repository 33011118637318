import MainRouterBusiness from "business/MainRouterBusiness";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useBaseSelector from "shared/hooks/useBaseSelector";
import useMe from "shared/hooks/user/useMe";
import { getBusinessAsync, setBusinessInit } from "shared/redux/business.slice";

export default ({ children }) => {
  const user = useMe();

  const { data: business, loading } = useBaseSelector("business.item");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessAsync(user?.relatedBusiness?.businessId));
    return () => dispatch(setBusinessInit("item"));
  }, []);

  if (!business) {
    return <></>;
  }

  return <>{children}</>;
};
