import BaseButton from "shared/components/shared/base/BaseButton";
import BaseInput from "shared/components/shared/base/BaseInput";
import { registerUserAsync } from "shared/redux/user.slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PasswordInput from "../shared/core/PasswordInput";
import { toast } from "react-toastify";
import Toast from "../shared/core/Toast";

export default ({ onChangeToLoginClick, variant, showTitle = true }) => {
  const [formLoading, setFormLoading] = useState(false);

  const [form, setForm] = useState({
    email: "",
    fullName: "",
    password: "",
    confirmPassword: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const onEnterHandler = (e) => {
      if (e.key === "Enter") {
        submit(form, setFormLoading);
      }
    };

    document.addEventListener("keypress", onEnterHandler);

    return () => document.removeEventListener("keypress", onEnterHandler);
  }, [form]);

  const onChange = (name) => (e) => {
    setForm((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const submit = async (payload, setLoading) => {
    try {
      setLoading(true);
      await dispatch(
        registerUserAsync({
          body: payload,
          query: { variant: variant },
        })
      ).unwrap();
    } catch (error) {
      toast(
        <Toast
          type="error"
          title="Erreur création compte"
          message={error.message}
        />
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-between">
      <div className="w-full text-2xl text-center">
        Créer votre compte Swiftr
      </div>
      <div className="w-full">
        <BaseInput
          placeholder="Email"
          className="lg:py-4 mb-4"
          value={form.email}
          onChange={onChange("email")}
          leftIcon="hugeicons:mail-02"
          tooltip="Email"
        />
        <BaseInput
          placeholder="Nom et prénom"
          className="lg:py-4 mb-4"
          value={form.fullName}
          onChange={onChange("fullName")}
          leftIcon="hugeicons:user"
          tooltip="Nom et prénom"
        />
        <PasswordInput
          placeholder="Mot de passe"
          className="lg:py-4 mb-4"
          value={form.password}
          onChange={onChange("password")}
          leftIcon="hugeicons:lock-key"
          tooltip="Mot de passe"
        />
        <PasswordInput
          placeholder="Répeter le mot de passe"
          className="lg:py-4 mb-4"
          value={form.confirmPassword}
          onChange={onChange("confirmPassword")}
          leftIcon="hugeicons:lock-key"
          tooltip="Répeter le mot de passe"
        />
        <BaseButton.Contained
          primary
          className="w-full rounded-full mb-4"
          onClick={() => submit(form, setFormLoading)}
          loading={formLoading}
        >
          Créer votre compte
        </BaseButton.Contained>

        <div
          className="w-full text-lg text-center cursor-pointer mb-4"
          onClick={onChangeToLoginClick}
        >
          Se connecter
        </div>
      </div>
    </div>
  );
};
