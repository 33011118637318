import _ from "lodash";
import { useEffect, useState } from "react";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import { RightContentTabletContainer } from "../shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import { LayoutContentContainer } from "shared/components/shared/core/Container";
import { appIcons } from "shared/constants/icons.constant";
import { OverviewCardV2 } from "../shared/OverviewCard";
import { tailwindTheme } from "shared/constants/theme.constant";
import colors from "tailwindcss/colors";
import BarChart from "../shared/charts/BarChart";
import { getBusinessSalesOverviewService } from "shared/services/analytics.service";
import { BusinessAnalyticsChart } from "./BusinessAnalyticsChart";

const BusinessAnalyticsTab = () => {
  const [cards, setCards] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      setLoading(true);
      const { data: analytics } = await getBusinessSalesOverviewService();
      setCards(buildOverviewCards(analytics));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <LayoutContentContainer>
      <div className="w-full h-auto grid grid-cols-2 lg:grid-cols-4 justify-center gap-x-2 gap-y-2 lg:gap-x-4 lg:gap-y-2 mb-4">
        {_.map(cards, (card, index) => (
          <OverviewCardV2 data={card} key={index} />
        ))}
      </div>
      <BusinessAnalyticsChart />
    </LayoutContentContainer>
  );
};

const BusinessAnalyticsTabTablet = () => {
  return <BusinessAnalyticsTab />;
};

export default WithResponsive(
  () => <BusinessAnalyticsTab />,
  () => (
    <RightContentTabletContainer
      breadCrumbItems={[tabsBreadcrumbs.dashboard]}
      firstContentMaxWidth={false}
    >
      <BusinessAnalyticsTabTablet />
    </RightContentTabletContainer>
  )
);

const cards = [
  {
    title: "Aujourd'hui",
    value: "12",
    icon: appIcons.money.default,
    iconBgColor: tailwindTheme.colors.primary.light?.[0],
  },
  {
    title: "Cette semaine",
    value: "12",
    icon: appIcons.money.default,
    iconBgColor: colors.blue[500],
  },
  {
    title: "Ce mois",
    value: "12",
    icon: appIcons.money.default,
    iconBgColor: colors.pink[700],
  },
];

const buildOverviewCards = (analytics) => {
  return [
    {
      title: "Aujourd'hui",
      value: analytics.day,
      icon: appIcons.money.default,
      iconBgColor: tailwindTheme.colors.primary.light?.[0],
    },
    {
      title: "Cette semaine",
      value: analytics?.week,
      icon: appIcons.money.default,
      iconBgColor: colors.blue[500],
    },
    {
      title: "Ce mois",
      value: analytics?.month,
      icon: appIcons.money.default,
      iconBgColor: colors.pink[700],
    },
    {
      title: "Cette année",
      value: analytics?.year,
      icon: appIcons.money.default,
      iconBgColor: colors.pink[700],
    },
  ];
};
