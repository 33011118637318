import { Icon } from "@iconify/react";
import { tailwindTheme } from "shared/constants/theme.constant";

export const appIcons = {
  home: {
    default: "hugeicons:home-04",
    selected: "hugeicons:home-01",
  },
  menu: {
    default: "hugeicons:menu-restaurant",
  },
  dashboard: {
    default: "hugeicons:dashboard-circle",
  },
  analytics: {
    default: "hugeicons:analytics-up",
  },
  tables: {
    default: "hugeicons:table-round",
  },
  business: {
    default: "hugeicons:building-06",
  },
  explore: {
    default: "hugeicons:search-01",
  },
  favorite: {
    default: "hugeicons:favourite",
  },
  cart: {
    default: "hugeicons:shopping-basket-01",
  },
  order: {
    default: "hugeicons:border-full",
  },
  orderHistory: {
    default: "hugeicons:time-02",
  },
  orderChef: {
    default: "hugeicons:chef-hat",
  },
  details: {
    default: "hugeicons:information-circle",
  },
  edit: {
    default: "hugeicons:pencil-edit-02",
  },
  template: {
    default: "hugeicons:layout-01",
  },
  userSettings: {
    default: "hugeicons:user-settings-01",
  },
  userAdd: {
    default: "hugeicons:user-add-01",
  },
  userGroup: {
    default: "hugeicons:user-group",
  },
  notification: {
    default: "hugeicons:notification-02",
  },
  me: {
    default: "hugeicons:user",
  },
  about: {
    default: "hugeicons:information-circle",
  },
  money: {
    default: "hugeicons:dollar-02",
  },
};

export const buildTabDefaultIcon = (icon) => (
  <Icon
    icon={appIcons?.[icon]?.default}
    fontSize={22}
    color={tailwindTheme.colors.custom.tab.default}
  />
);

export const buildTabSelectedIcon = (icon) => (
  <Icon
    icon={appIcons?.[icon]?.selected ?? appIcons?.[icon]?.default}
    fontSize={22}
    color={tailwindTheme.colors.custom.tab.selected}
  />
);
