import _ from "lodash";
import Tabs from "shared/components/shared/core/Tabs";
import useHomeFetchNotifications from "shared/hooks/useHomeFetchNotifications";
import MainDrawer from "shared/components/shared/core/MainDrawer";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import SidebarBusiness from "./components/shared/SidebarBusiness";
import useTabsBusiness from "./hooks/useTabsBusiness";
import useBaseSelector from "shared/hooks/useBaseSelector";

const RootLayoutMobile = ({ children }) => {
  const selections = useBaseSelector("order.cart.selections");

  const tabs = useTabsBusiness();

  useHomeFetchNotifications();

  return (
    <div className="w-full h-full relative">
      {children}
      <MainDrawer />
      <Tabs
        tabs={tabs}
        watchRouteDirectoryIndex={1}
        badgeNumberMap={{
          cart: selections?.length,
        }}
      />
    </div>
  );
};

const RootLayoutTablet = ({ children }) => {
  useHomeFetchNotifications();

  return (
    <div className="w-screen h-screen flex flex-row items-start justify-start overflow-y-hidden">
      <div className="min-w-[70px] max-w-[70px] h-full rounded-lg">
        <SidebarBusiness />
      </div>
      <div className="w-[calc(100%-70px)] h-full px-4 pt-2">{children}</div>
    </div>
  );
};

export default WithResponsive(RootLayoutMobile, RootLayoutTablet);
