import { ApplicationContext } from "context";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { paginationLimit } from "shared/constants/shared.constant";
import {
  getNotificationsAsync,
  setNotificationInit,
} from "shared/redux/notification.slice";

export default () => {
  const variant = useContext(ApplicationContext);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getNotificationsAsync({
        payload: {
          query: {
            limit: paginationLimit,
            variant,
          },
        },
        options: {
          reducerOptions: {
            reset: true,
          },
        },
      })
    );
    return () => dispatch(setNotificationInit("main"));
  }, []);
};
