import { useSelector } from "react-redux";
import AuthBusiness from "./AuthBusiness";

export default ({ children }) => {
  const { data: user } = useSelector((state) => state.user?.me);

  if (!user) {
    return <AuthBusiness />;
  }

  return <>{children}</>;
};
