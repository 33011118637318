import { lazy, Suspense } from "react";
import RootBusinessPortalBusiness from "business/components/business/RootBusinessPortalBusiness";
import AuthcheckPortalBusiness from "business/components/user/AuthcheckPortalBusiness";
import AuthPortalBusiness from "business/components/user/AuthPortalBusiness";
import UserPortalBusiness from "business/components/user/UserPortalBusiness";
import { Outlet } from "react-router-dom";
//import UserEmailPortal from "shared/components/user/UserEmailPortal";

const MainRouterBusiness = lazy(() => import("business/MainRouterBusiness"));

export default () => (
  <AuthcheckPortalBusiness>
    <AuthPortalBusiness>
      <UserPortalBusiness>
        <RootBusinessPortalBusiness>
          <Suspense>
            <MainRouterBusiness>
              <Outlet />
            </MainRouterBusiness>
          </Suspense>
        </RootBusinessPortalBusiness>
      </UserPortalBusiness>
    </AuthPortalBusiness>
  </AuthcheckPortalBusiness>
);
