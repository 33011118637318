import { useGoogleLogin } from "@react-oauth/google";
import BaseButton from "../base/BaseButton";
import { useDispatch } from "react-redux";
import {
  getGoogleLoginAccessTokenAsync,
  loginGoogleUserAsync,
} from "shared/redux/user.slice";
import { useContext, useEffect, useState } from "react";
import { AuthModalContext } from "context";
import { useNavigate } from "react-router-dom";

export default () => {
  const [__, setIsAuthBottomSheetOpen] = useContext(AuthModalContext);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const code = params.get("code");

    if (!code) {
      return navigate("/");
    }

    oauthLogin(code);
  }, []);

  const oauthLogin = async (code) => {
    try {
      setLoading(true);

      const response = await dispatch(
        getGoogleLoginAccessTokenAsync({ code })
      ).unwrap();

      console.log(response.data);

      await dispatch(
        loginGoogleUserAsync({
          body: { access_token: response.data.access_token },
          query: {
            variant: "internal",
          },
        })
      ).unwrap();
    } catch (error) {
    } finally {
      setLoading(false);
      navigate("/");
    }
  };

  const onGoogleLoginSuccess = async (tokenResponse) => {
    try {
      setLoading(true);
      await dispatch(
        loginGoogleUserAsync({
          body: tokenResponse,
          query: {
            variant: "internal",
          },
        })
      ).unwrap();
      setIsAuthBottomSheetOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = useGoogleLogin({
    flow: "auth-code",
    redirectUri: `${window.location.origin}`,
    ux_mode: "redirect",
    //onSuccess: onGoogleLoginSuccess,
  });

  return (
    <BaseButton.Contained
      secondary
      className="w-full rounded-full _shadowInput"
      onClick={handleLogin}
      loading={loading}
      icon="hugeicons:google"
      iconSize={28}
    >
      Se connecter avec Google
    </BaseButton.Contained>
  );
};
