import { getTabsBusinessByRole } from "business/constants/tabsBusiness.constant";
import { useEffect, useState } from "react";
import useMe from "shared/hooks/user/useMe";

export default (variant = "mobile") => {
  const user = useMe();

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs(getTabsBusinessByRole(user?.relatedBusiness?.role, variant));
  }, [user?.relatedBusiness?.role]);

  return tabs;
};
