import _ from "lodash";
import {
  buildTabDefaultIcon,
  buildTabSelectedIcon,
  appIcons,
} from "shared/constants/icons.constant";

export const tabsMapBusiness = {
  dashboard: {
    label: "Dashboard",
    value: "", //on route split by '/' index 2, value is undefined, tab will ne shown as selected
    route: "/",
    defaultIcon: buildTabDefaultIcon("dashboard"),
    selectedIcon: buildTabSelectedIcon("dashboard"),
  },
  analytics: {
    label: "Analytics",
    value: "analytics", //on route split by '/' index 2, value is undefined, tab will ne shown as selected
    route: "/analytics",
    defaultIcon: buildTabDefaultIcon("analytics"),
    selectedIcon: buildTabSelectedIcon("analytics"),
  },
  menu: {
    label: "Menu",
    value: "menu", //on route split by '/' index 2, value is undefined, tab will ne shown as selected
    route: "/menu",
    defaultIcon: buildTabDefaultIcon("menu"),
    selectedIcon: buildTabSelectedIcon("menu"),
  },
  tables: {
    label: "Tables",
    value: "tables",
    route: "tables",
    defaultIcon: buildTabDefaultIcon("tables"),
    selectedIcon: buildTabSelectedIcon("tables"),
  },
  cart: {
    label: `Panier`,
    value: "cart",
    route: "/cart",
    defaultIcon: buildTabDefaultIcon("cart"),
    selectedIcon: buildTabSelectedIcon("cart"),
  },
  order: {
    label: "Commandes",
    value: "order",
    route: "/order",
    defaultIcon: buildTabDefaultIcon("order"),
    selectedIcon: buildTabSelectedIcon("order"),
  },

  orderChef: {
    label: "Chef",
    value: "orderChef",
    route: "/orderChef",
    defaultIcon: buildTabDefaultIcon("orderChef"),
    selectedIcon: buildTabSelectedIcon("orderChef"),
  },
  business: {
    label: "Business",
    value: "business",
    route: "/business",
    defaultIcon: buildTabDefaultIcon("business"),
    selectedIcon: buildTabSelectedIcon("business"),
  },
  notification: {
    label: "Notifications",
    value: "notification",
    route: "notification",
    defaultIcon: buildTabDefaultIcon("notification"),
    selectedIcon: buildTabSelectedIcon("notification"),
  },
  me: {
    label: "Moi",
    value: "me",
    route: "/me",
    defaultIcon: buildTabDefaultIcon("me"),
    selectedIcon: buildTabSelectedIcon("me"),
  },
};

const tabsRoleMap = {
  owner: [
    "dashboard",
    "menu",
    "tables",
    "cart",
    "order",
    "business",
    "analytics",
    "me",
  ],
  admin: [
    "dashboard",
    "menu",
    "tables",
    "cart",
    "order",
    "business",
    "analytics",
    "me",
  ],
  cashier: ["menu", "tables", "cart", "order", "business", "me"],
  waiter: ["menu", "tables", "cart", "order", "business", "me"],
  chef: ["menu", "cart", "order", "business", "me"],
};

const sidebarTabsRoleMap = {
  owner: [
    "dashboard",
    "menu",
    "tables",
    "order",
    "orderChef",
    "business",
    "analytics",
    "notification",
  ],
  admin: [
    "dashboard",
    "menu",
    "tables",
    "order",
    "orderChef",
    "business",
    "analytics",
    "notification",
  ],
  cashier: ["menu", "tables", "order", "orderChef", "business", "notification"],
  waiter: ["menu", "tables", "order", "orderChef", "business", "notification"],
  chef: ["menu", "tables", "order", "orderChef", "business", "notification"],
};

export const tabsBreadcrumbs = {
  menu: {
    title: "Menu",
    icon: appIcons.menu.default,
    link: "/",
  },
  dashboard: {
    title: "Dashboard",
    icon: appIcons.dashboard.default,
    link: "/",
  },
  analytics: {
    title: "Analytics",
    icon: appIcons.analytics.default,
    link: "/",
  },
  tables: {
    title: "Tables",
    icon: appIcons.tables.default,
    link: "/tables",
  },
  me: {
    title: "Moi",
    icon: appIcons.me.default,
    link: "/me",
  },
  about: {
    title: "A propos",
    icon: appIcons.about.default,
    link: "/about",
  },
  notification: {
    title: "Notification",
    icon: appIcons.notification.default,
    link: "/notification",
  },
  order: {
    title: "Commandes",
    icon: appIcons.order.default,
    link: "/order",
  },
  orderHistory: {
    title: "Historique",
    icon: appIcons.orderHistory.default,
    link: "/order/history",
  },
  orderChef: {
    title: "Commandes Vue Chef",
    iicon: appIcons.orderChef.default,
    link: "/orderChef",
  },
  orderDetails: (params) => ({
    title: params?.id,
    icon: appIcons.details.default,
    link: "/order/" + params?.id,
  }),
  orderEdit: (params) => ({
    title: params?.id,
    icon: appIcons.edit.default,
    link: "/order/" + params?.id,
  }),
  business: {
    title: "Business",
    icon: appIcons.business.default,
    link: "/business",
  },
  businessManageProfile: {
    title: "Infos",
    icon: appIcons.details.default,
    link: "/business/manage/profile",
  },
  businessManageMenu: {
    title: "Configurer le menu",
    icon: appIcons.menu.default,
    link: "/business/manage/menu/items",
  },
  businessManageTemplate: {
    title: "Configure la template du menu",
    icon: appIcons.template.default,
    link: "/business/manage/menu/template",
  },
  businessManageTeam: {
    title: "Manager l'équipe",
    icon: appIcons.userSettings.default,
    link: "/business/manage/team",
  },
  businessManageTeamAdd: {
    title: "Ajouter un personnel",
    icon: appIcons.userAdd.default,
    link: "/business/manage/team/add",
  },
  businessTeam: {
    title: "Manager l'équipe",
    icon: appIcons.userGroup.default,
    link: "/business/manage/team",
  },
};

export const getTabsBusinessByRole = (role, variant) =>
  _.chain(tabsMapBusiness)
    .pick(variant === "mobile" ? tabsRoleMap[role] : sidebarTabsRoleMap[role])
    .values()
    .value();
