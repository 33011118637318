import {
    setBusinessUser,
  setBusinessUserNone,
  setBusinessUserRole,
} from "shared/redux/user.slice";

export const setBusinessUserRoleUpdateDispatch = (dispatch, data) => {
  dispatch(setBusinessUserRole(data));
};

export const setBusinessUserNoneDispatch = (dispatch, data) => {
  dispatch(setBusinessUserNone());
};

export const setBusinessUserDispatch = (dispatch, data) => {
  dispatch(setBusinessUser(data));
};
