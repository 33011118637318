import {
  HeaderBackContainer,
  LayoutContainer,
  LayoutContentContainer,
  TitledContent,
} from "shared/components/shared/core/Container";
import BaseInput from "shared/components/shared/base/BaseInput";
import { useState } from "react";
import useBaseSelector from "shared/hooks/useBaseSelector";
import ImageInput from "shared/components/shared/core/ImageInput";
import { useDispatch } from "react-redux";
import { updateBusinessAsync } from "shared/redux/business.slice";
import useBusiness from "business/hooks/useBusiness";
import { useNavigate } from "react-router-dom";
import BusinessRolePortal from "../shared/guards/BusinessRolePortal";
import { businessUserRoles } from "shared/constants/business.constant";
import { RightContentTabletContainer } from "../shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import { ConfirmButton } from "shared/components/shared/core/Buttons";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import BaseTextArea from "shared/components/shared/base/BaseTextArea";
import BusinessProfileBusiness from "./BusinessProfileBusiness";

const BusinessManageProfile = () => {
  const business = useBusiness();
  const { loading } = useBaseSelector("business.form");

  const [form, setForm] = useState({
    name: business?.name ?? "",
    address: business?.address ?? "",
    avatar: business?.avatar ?? "",
    thumbnail: business?.thumbnail ?? "",
    info: business?.info ?? "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onChange = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    dispatch(
      updateBusinessAsync({
        payload: {
          id: business.id,
          body: form,
        },
        options: {
          onSuccess: () => {
            navigate("/business");
          },
        },
      })
    );
  };

  return (
    <>
      <TitledContent
        title="Logo"
        titleClassName="text-md"
        className="w-full items-center"
      >
        <ImageInput
          className="rounded-full border-[2px] border-primary-main"
          imageClassName="rounded-full"
          onFileChange={(file) => onChange("avatar", file)}
          value={form.avatar}
        />
      </TitledContent>

      <TitledContent
        title="Image"
        titleClassName="text-md"
        className="w-full items-center mb-6"
      >
        <ImageInput
          className="w-[280px] h-[180px] rounded-lg border-[2px] border-primary-main"
          imageClassName="rounded-lg"
          onFileChange={(file) => onChange("thumbnail", file)}
          value={form.thumbnail}
        />
      </TitledContent>

      <TitledContent title="Nom Business" titleClassName="text-md">
        <BaseInput
          value={form.name}
          onChange={(e) => onChange("name", e.target.value)}
          className="rounded-lg"
        />
      </TitledContent>
      <TitledContent title="Addresse" titleClassName="text-md">
        <BaseInput
          value={form.address}
          onChange={(e) => onChange("address", e.target.value)}
          className="rounded-lg"
        />
      </TitledContent>

      <TitledContent title="Info" titleClassName="text-md">
        <BaseTextArea
          value={form.info}
          onChange={(e) => onChange("info", e.target.value)}
          className="rounded-lg"
        />
      </TitledContent>
      <ConfirmButton onClick={onSubmit} loading={loading} />
    </>
  );
};

const BuinessManageProfileMobile = () => {
  return (
    <HeaderBackContainer>
      <BusinessManageProfile />
    </HeaderBackContainer>
  );
};

const BusinessManageProfileTablet = () => {
  return <BusinessManageProfile />;
  // return (
  //   <div className="w-full flex flex-row items-start justify-between">
  //     <div className="w-[450px] sticky top-0 mr-4">
  //       <BusinessProfileBusiness />
  //     </div>
  //     <div className="flex-1">
  //       <BusinessManageProfile />
  //     </div>
  //   </div>
  // );
};

const AuthorizedBusinessManageProfile = ({ children }) => (
  <BusinessRolePortal
    roles={[businessUserRoles.owner, businessUserRoles.admin]}
  >
    {children}
  </BusinessRolePortal>
);

export default WithResponsive(
  () => (
    <AuthorizedBusinessManageProfile>
      <BuinessManageProfileMobile />
    </AuthorizedBusinessManageProfile>
  ),
  () => (
    <AuthorizedBusinessManageProfile>
      <RightContentTabletContainer
        breadCrumbItems={[
          tabsBreadcrumbs.business,
          tabsBreadcrumbs.businessManageProfile,
        ]}
      >
        <BusinessManageProfileTablet />
      </RightContentTabletContainer>
    </AuthorizedBusinessManageProfile>
  )
);
